import * as React from "react";
import classnames from "classnames";
import * as Icon from "../../icons";
import SiteLink from "./SiteLink";
import {
  VITE_ADMIN_URL,
  VITE_IP_URL,
  VITE_SITE,
  VITE_VALINOR_URL,
} from "../../env";

const adminHost = VITE_ADMIN_URL;

const switchToOptions = {
  console: undefined,
  valinor: (
    <SiteLink
      url={VITE_VALINOR_URL}
      icon={<Icon.BractletSymbol />}
      title="Valinor"
      className="valinor"
      key="valinor"
    />
  ),
  ip: (
    <SiteLink
      url={VITE_IP_URL}
      icon={<Icon.BractletSymbol />}
      title="Intelligence Platform"
      className="ip"
      key="ip"
    />
  ),
  admin: (
    <div key={"admin"}>
      <h3 className={classnames("site-link admin-title")}>
        <div className={classnames("bractlet-symbol-container", "admin main")}>
          <Icon.BractletSymbol />
        </div>
        {"Admin"}
      </h3>
      <React.Fragment>
        <SiteLink
          url={`${adminHost}/users-and-accounts`}
          icon={<Icon.UsersAndAccounts />}
          title="Users and Accounts"
          className="admin"
        />
        <SiteLink
          url={`${adminHost}/sensors-and-integrations`}
          icon={<Icon.SensorsAndIntegrations />}
          title="Sensors and Integrations"
          className="admin"
        />
      </React.Fragment>
    </div>
  ),
} as const;
delete switchToOptions[VITE_SITE];

const SwitchToButton = () => {
  const [selected, setSelected] = React.useState(false);
  const [container, setContainer] = React.useState<
    HTMLDivElement | null | undefined
  >(null);
  const updateContainer = React.useCallback((node) => {
    if (node != null) {
      setContainer(node);
    }
  }, []);
  React.useEffect(() => {
    const handleMouseOut = () => setSelected(false);
    const clickHandler = (e: MouseEvent) => {
      const target = e.target;
      if (container && target instanceof Node && !container.contains(target)) {
        setSelected(false);
      }
    };

    if (
      container &&
      container.parentElement &&
      container.parentElement instanceof Node
    ) {
      container.parentElement.addEventListener("mouseleave", handleMouseOut);
      document.addEventListener("mousedown", clickHandler, false);
      return () => {
        // @ts-expect-error - TS2531 - Object is possibly 'null'.
        container.parentElement.removeEventListener(
          "mouseleave",
          handleMouseOut
        );
        document.removeEventListener("mousedown", clickHandler, false);
      };
    }
  });
  return (
    <div
      className={classnames("switch-to-container", { selected: selected })}
      ref={updateContainer}
      onClick={() => setSelected(!selected)}
    >
      <div className="nav-item-separator" />
      <button
        className={classnames("nav-item switch-to-button", {
          selected: selected,
        })}
      >
        <div className="nav-icon">{<Icon.AdminLogo />}</div>
        <h3 className="nav-title">{"Switch to"}</h3>
      </button>
      <div className="site-options-container">
        <React.Fragment>{Object.values(switchToOptions)}</React.Fragment>
      </div>
    </div>
  );
};

export default SwitchToButton;
