// This is a temporary utility used to QA test the utilities data ingested from Arcadia
// For users with the utilities-arcadia-qa flag set to "on", every request to utilities service should include the header: X-Route-To=utilities_arcadia

import { useFeatureFlag } from "@src/featureFlags";

export type UtilitiesRouteToHeader = "utilities_arcadia" | "utilities";

// Returns the value to set the X-Route-To header to for utility endpoints
export function useUtilitiesArcadiaQAHeader(): UtilitiesRouteToHeader {
  const flagEnabled = useFeatureFlag("utilities-arcadia-qa", "off") === "on";
  return flagEnabled ? "utilities_arcadia" : "utilities";
}
