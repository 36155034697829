import * as React from "react";
import classnames from "classnames";
import { useDidClickOutsideElement } from "../../../../global_functions/hooks";
import Icon, { IconNames } from "../../icons/Icon/Icon";
import Text from "../../type/Text/Text";

import { User } from "src/types";
import {
  LOGIN_URL,
  VITE_BACKPACK_URL,
  VITE_IP_URL,
  VITE_SITE,
} from "../../../../env";
import { ExtendedSemanticColors, Include } from "../../../colors";
import classNames from "classnames";
import { matchPath, useLocation } from "react-router-dom";
import Skeleton from "../../skeleton/Skeleton";

export type UserHeaderProps = {
  className?: string;
  user?: User | null;
  accountsURL: string;
  settingsURL: string;
  logoutURL?: string;
};

const backpackToBractlet = new Map<string, string>([
  ["/site/:siteId", "/site/:siteId"],
  ["/site/:siteId/overview", "/site/:siteId/dashboard/home"],
  [
    "/site/:siteId/utilities/bills/:utility",
    "/site/:siteId/dashboard/utilities/bills/:utility",
  ],
]);
const bractletToBackpack = new Map(
  [...backpackToBractlet].map(([k, v]) => [v, k])
);

const routeMapping =
  VITE_SITE === "console" ? backpackToBractlet : bractletToBackpack;

export function getAppSwitcherHref(baseUrl: string, pathname: string) {
  if (pathname === "/") {
    return baseUrl + "/";
  }

  let match: ReturnType<typeof matchPath> | null = null;
  for (const path of routeMapping.keys()) {
    match = matchPath(pathname, {
      path,
      exact: true,
      strict: true,
    });
    if (match) {
      break;
    }
  }

  if (!match) {
    return baseUrl + "/";
  }

  let newPathname = routeMapping.get(match?.path);

  if (match?.params) {
    Object.keys(match.params).forEach((param) => {
      const value = match?.params?.[param];
      if (value)
        newPathname = newPathname?.replace(new RegExp(`:${param}`, "g"), value);
    });
  }

  return baseUrl + newPathname;
}

const switchToBaseUrl =
  VITE_SITE === "console" ? VITE_IP_URL : VITE_BACKPACK_URL;

const UserHeader = ({
  className,
  user,
  accountsURL,
  settingsURL,
}: UserHeaderProps) => {
  const firstInitial = (value: string | null | undefined) =>
    `${value || ""}`[0] || "";

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const userContainerRef = useDidClickOutsideElement(setDropdownOpen);

  const { pathname } = useLocation();
  const href = getAppSwitcherHref(switchToBaseUrl, pathname);

  if (!user) {
    return (
      <div className="flex h-[70px] w-[220px] items-center justify-between px-7 py-4">
        <Skeleton className="h-5 w-28" />
        <Skeleton className="h-9 w-9 rounded-full" />
      </div>
    );
  }
  return (
    <div
      data-testid="user-header-container"
      className={classnames(
        "group relative flex min-w-[220px] cursor-pointer items-center justify-between px-7 py-4",
        {
          "bg-white": dropdownOpen,
        },
        className
      )}
      ref={userContainerRef}
      onClick={() => setDropdownOpen(!dropdownOpen)}
    >
      {/* Static Part */}
      <Text
        variant="sb_t-14-500"
        as="span"
        color="tertiary"
        className="leading-8 transition-colors group-hover:text-straps-primary"
      >
        {`${user.firstName ?? ""} ${user.lastName ?? ""}`}
      </Text>
      <div className="flex h-9 w-9 items-center justify-center overflow-hidden rounded-full border-2 border-white bg-straps-primary shadow-md">
        {user.imageURL ? (
          <img src={user.imageURL} alt="user-avatar" className="h-9 w-9" />
        ) : (
          <div className="text-xs text-white">
            {firstInitial(user.firstName)}
            {firstInitial(user.lastName)}
          </div>
        )}
      </div>
      {/* Floating Part */}
      <div
        data-testid="user-header-dropdown-container"
        className={classnames(
          "pointer-events-none absolute right-0 top-full w-full overflow-hidden bg-white opacity-0 shadow-lg transition-opacity",
          {
            "pointer-events-auto opacity-100": dropdownOpen,
          }
        )}
      >
        <div className="border-b border-t border-straps-tertiary border-opacity-25 px-7 py-4">
          {user.superAdmin && accountsURL && (
            <LinkButton
              data-testid="user-header-accounts-button"
              onClick={() => {
                setDropdownOpen(false);
              }}
              href={accountsURL}
              icon="user"
              color="accent-1"
            >
              Accounts
            </LinkButton>
          )}
          {settingsURL && (
            <LinkButton
              data-testid="user-header-settings-button"
              onClick={() => {
                setDropdownOpen(false);
              }}
              href={settingsURL}
              icon="gear"
              color="brand"
            >
              Settings
            </LinkButton>
          )}

          {user.userPermissions === "admin" && (
            <LinkButton
              data-testid="user-header-admin-button"
              color="warning"
              onClick={() => {
                setDropdownOpen(false);
              }}
              href={"/admin"}
              icon="admin"
            >
              Admin
            </LinkButton>
          )}

          <LinkButton
            data-testid="user-header-logout-button"
            color="primary"
            onClick={() => {
              setDropdownOpen(false);
            }}
            href={LOGIN_URL + "/logout"}
            icon="circle-out"
          >
            Logout
          </LinkButton>
        </div>
        <div className="group/switch flex flex-col px-7 py-4">
          <Text
            variant="sb_t-10-500"
            color="tertiary"
            className="mb-1 group-hover/switch:text-straps-primary"
          >
            Switch To
          </Text>
          <LinkButton
            data-testid="user-header-switch-to-button"
            href={href}
            color="body"
            icon={VITE_SITE === "console" ? "bractlet" : "full"}
            iconSize="medium"
          >
            {VITE_SITE === "console" ? "Bractlet" : "Backpack"}
          </LinkButton>

          {user.superAdmin && (
            <LinkButton
              data-testid="user-header-switch-to-arcpack-button"
              href="/arcpack"
              icon="utilities"
              color="body"
            >
              <span>
                Arc<span className="text-straps-brand">Pack</span>
              </span>
            </LinkButton>
          )}
        </div>
      </div>
    </div>
  );
};

type Colors = Include<
  ExtendedSemanticColors,
  "primary" | "brand" | "accent-1" | "body" | "warning"
>;

const colorMap: Record<Colors, string> = {
  primary:
    "bg-straps-primary focus:outline-straps-primary-hover hover:bg-straps-primary-hover",
  brand:
    "bg-straps-brand focus:outline-straps-brand-hover hover:bg-straps-brand-hover",
  "accent-1":
    "bg-straps-accent-1 focus:outline-straps-accent-1-hover hover:bg-straps-accent-1-hover",
  // Extra note for body color - does this indicate we should have a set body-hover variant?
  body: "bg-straps-body focus:outline-straps-brand hover:bg-[#E2E5E8]", // exceptional use of non-semantic color
  warning:
    "bg-straps-warning focus:outline-straps-warning-hover hover:bg-straps-warning-hover",
};

const textStylesMap: Record<Colors, string> = {
  primary: "text-pure-white",
  brand: "text-pure-white",
  "accent-1": "text-pure-white",
  body: " text-straps-primary",
  warning: "text-straps-primary",
};

// todo - Straps version?
function LinkButton(props: {
  href: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children: React.ReactNode;
  icon: IconNames;
  "data-testid": string;
  color: Colors;
  iconSize?: "medium" | "large";
  className?: string;
}) {
  return (
    <a
      data-testid={props["data-testid"]}
      onClick={props.onClick}
      href={props.href}
      className={classnames(
        props.className,
        "mb-2 block outline-none last:mb-0",
        colorMap[props.color]
      )}
    >
      <span
        className={classNames(
          "text flex h-[34px] w-40 cursor-pointer flex-row items-center justify-between px-5 py-1.5 text-xs font-bold uppercase transition-all hover:shadow-lg",
          textStylesMap[props.color]
        )}
      >
        {props.children}
        <Icon
          name={props.icon}
          size={props.iconSize || "large"}
          color={props.icon === "bractlet" ? "brand" : undefined}
        />
      </span>
    </a>
  );
}

export default UserHeader;
