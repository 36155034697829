import createClient from "openapi-fetch";

import {
  components as activityLogApiComponents,
  paths as activityLogApiPaths,
} from "./lib/generated-activity-log-api"; // generated from openapi-typescript

import {
  components as ledgerApiComponents,
  paths as ledgerApiPaths,
} from "./lib/generated-ledger-api"; // generated from openapi-typescript

import {
  components as reporterComponents,
  paths as reporterPaths,
} from "./lib/generated-reporter"; // generated from openapi-typescript

import {
  components as siteComponents,
  paths as sitePaths,
} from "./lib/generated-site"; // generated from openapi-typescript

import {
  components as tenantsAndSpacesComponents,
  paths as tenantsAndSpacesPaths,
} from "./lib/generated-tenants-and-spaces"; // generated from openapi-typescript

import {
  components as utilitiesComponents,
  paths as utilitiesPaths,
} from "./lib/generated-utilities"; // generated from openapi-typescript

import {
  components as projectsComponents,
  paths as projectsPaths,
} from "./lib/generated-projects"; // generated from openapi-typescript

import {
  components as d3Components,
  paths as d3Paths,
} from "./lib/generated-d3"; // generated from openapi-typescript

import {
  components as equipmentComponents,
  paths as equipmentPaths,
} from "./lib/generated-equipment"; // generated from openapi-typescript

import {
  components as vendorsComponents,
  paths as vendorsPaths,
} from "./lib/generated-vendors"; // generated from openapi-typescript

import {
  components as certificationsComponents,
  paths as certificationsPaths,
} from "./lib/generated-certifications"; // generated from openapi-typescript

import {
  components as arcadiaComponents,
  paths as arcadiaPaths,
} from "./lib/generated-arcadia"; // generated from openapi-typescript

import {
  components as marketplaceComponents,
  paths as marketplacePaths,
} from "./lib/generated-marketplace"; // generated from openapi-typescript

import {
  VITE_ACTIVITY_LOGS_BASE_URL,
  VITE_LEDGER_BASE_URL,
  VITE_REPORTER_BASE_URL,
  VITE_SITE_SERVICE_BASE_URL,
  VITE_TENANTS_AND_SPACES_BASE_URL,
  VITE_UTILITIES_BASE_URL,
  VITE_UPLOAD_BASE_URL,
  VITE_MARKETPLACE_BASE_URL,
  VITE_ARCADIA_BASE_URL,
} from "../../../env";

import Cookies from "js-cookie";
import { requestShouldThrow } from "../../request";

export type ClientPaths = {
  arcadia: arcadiaPaths;
  activityLog: activityLogApiPaths;
  ledger: ledgerApiPaths;
  reporter: reporterPaths;
  site: sitePaths;
  tenantsAndSpaces: tenantsAndSpacesPaths;
  utilities: utilitiesPaths;
  projects: projectsPaths;
  equipment: equipmentPaths;
  marketplace: marketplacePaths;
};

export type ClientOutput = {
  arcadia: arcadiaComponents["schemas"];
  activityLog: activityLogApiComponents["schemas"];
  ledger: ledgerApiComponents["schemas"];
  reporter: reporterComponents["schemas"];
  site: siteComponents["schemas"];
  vendors: vendorsComponents["schemas"];
  certifications: certificationsComponents["schemas"];
  tenantsAndSpaces: tenantsAndSpacesComponents["schemas"];
  utilities: utilitiesComponents["schemas"];
  projects: projectsComponents["schemas"];
  d3: d3Components["schemas"];
  equipment: equipmentComponents["schemas"];
  marketplace: marketplaceComponents["schemas"];
};

const headers = {
  Authorization: `Bearer ${Cookies.get("jwt") ?? ""}`,
} satisfies HeadersInit;

const customFetch: typeof fetch = (...args) => {
  const [, init] = args;
  const errorMessage = requestShouldThrow({
    safe: init?.method === "GET" || !init?.method,
    // prodonly only matters for 3rd parties i.e. energy star
    prodOnly: false,
    isTestSite: false, // TODO - add check for test site id if needed
  });
  if (errorMessage) throw new Error(errorMessage);
  return globalThis.fetch(...args);
};

const FetchClients = {
  arcadia: createClient<arcadiaPaths>({
    baseUrl: VITE_ARCADIA_BASE_URL + "/arcadia",
    headers,
    fetch: customFetch,
  }),
  activityLog: createClient<activityLogApiPaths>({
    baseUrl: VITE_ACTIVITY_LOGS_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  ledger: createClient<ledgerApiPaths>({
    baseUrl: VITE_LEDGER_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  reporter: createClient<reporterPaths>({
    baseUrl: VITE_REPORTER_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  site: createClient<sitePaths>({
    baseUrl: VITE_SITE_SERVICE_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  tenantsAndSpaces: createClient<tenantsAndSpacesPaths>({
    baseUrl: VITE_TENANTS_AND_SPACES_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  utilities: createClient<utilitiesPaths>({
    baseUrl: VITE_UTILITIES_BASE_URL + "/sites",
    headers,
    fetch: customFetch,
  }),
  projects: createClient<projectsPaths>({
    baseUrl: VITE_SITE_SERVICE_BASE_URL + "/projects",
    headers,
    fetch: customFetch,
  }),
  d3: createClient<d3Paths>({
    baseUrl: VITE_UPLOAD_BASE_URL,
    headers,
    fetch: customFetch,
  }),
  vendors: createClient<vendorsPaths>({
    baseUrl: VITE_SITE_SERVICE_BASE_URL + "/vendors",
    headers,
    fetch: customFetch,
  }),
  certifications: createClient<certificationsPaths>({
    baseUrl: VITE_SITE_SERVICE_BASE_URL + "/certifications-report",
    headers,
    fetch: customFetch,
  }),
  equipment: createClient<equipmentPaths>({
    baseUrl: VITE_TENANTS_AND_SPACES_BASE_URL + "/equipment",
    headers,
    fetch: customFetch,
  }),
  marketplace: createClient<marketplacePaths>({
    baseUrl: VITE_MARKETPLACE_BASE_URL + "/marketplace",
    headers,
    fetch: customFetch,
  }),
} as const;

export default FetchClients;
