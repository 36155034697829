import * as React from "react";
import { Route, Link, useParams, useLocation } from "react-router-dom";
import { find, orderBy, keyBy } from "lodash";

import SitesContext, { useSite } from "../context/SitesContext";
import Select from "./Select";
import * as Icons from "../icons";

import styles from "./BuildingAccountSwitcher.module.scss";

import { isIP, isDev } from "../env";

const BuildingAccountSwitcher = () => {
  const location = useLocation();
  const params = useParams();
  // @ts-expect-error - TS2339 - Property 'siteId' does not exist on type '{}'. | TS2339 - Property 'accountId' does not exist on type '{}'.
  const { siteId, accountId } = params;
  const { allSites: sites } = React.useContext(SitesContext);
  const currentSite = useSite(siteId);

  const accountName = React.useMemo(() => {
    if (currentSite) return currentSite.accountName;

    const site = accountId && find(sites, { accountId: Number(accountId) });
    return site ? site.accountName : null;
  }, [sites, accountId, currentSite]);

  const sortedSites = React.useMemo(() => {
    const firstAccountId = currentSite
      ? currentSite.accountId
      : Number(accountId);

    if (!firstAccountId) return sites;

    // stable sort, so sites within accounts remain in default (alphabetical) sort order
    return orderBy(sites, [
      // put sites from current account first
      (site: any) => site.accountId !== firstAccountId,
      // then sort accounts alphabetically
      "account",
    ]);
  }, [sites, accountId, currentSite]);

  const numAccounts = React.useMemo(() => {
    return Object.keys(keyBy(sites, "accountId")).length;
  }, [sites]);

  // @ts-expect-error - TS7006 - Parameter 'id' implicitly has an 'any' type.
  const getSiteLink = (id) =>
    location.pathname.replace(siteId ? /^\/site\/\d+/ : /.*/, `/site/${id}`);

  const getAccountLink = (id: number | string) =>
    location.pathname.replace(
      accountId ? /^\/account\/\d+/ : /.*/,
      `/account/${id}`
    );

  if (!currentSite && !accountName) return null;

  return (
    <Select
      label={
        <React.Fragment>
          {currentSite ? currentSite.name : accountName}
          <Icons.Caret className="bractlet-select--button-caret" />
        </React.Fragment>
      }
      selected={siteId}
      openOnLabelClick
      inputLabel="Search for your Building..."
      inputIcon="Search"
      className="building-account-switcher"
      onChange={() => {}}
      options={sortedSites.map((site) => ({
        id: site.id,
        label: site.name,
        // eslint-disable-next-line
        className: styles.option_container,
        nodeLabel: (
          <Link
            className={styles["--site-link"]}
            title={site.name}
            to={getSiteLink(site.id)}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles["--site-image"]}>
              {!isDev && site.imageURL ? (
                <img alt={site.name} src={site.imageURL} />
              ) : (
                <Icons.BuildingPlaceholder />
              )}
            </div>
            <span className={styles["--site-name"]}>{site.name}</span>
          </Link>
        ),
        site,
      }))}
      groupBy={
        numAccounts > 1
          ? { key: "site.accountId", headerKey: "site.account" }
          : null
      }
      renderGroupHeader={
        isIP
          ? (id: number | string, name: string) => (
              <Link
                to={getAccountLink(id)}
                className="bractlet-select--option-group-header --account-link"
                onClick={(e) => e.stopPropagation()}
              >
                {name}
                <Icons.Caret />
              </Link>
            )
          : undefined
      }
      searchable
    />
  );
};

const BuildingAccountSwitcherRouter = () => (
  <Route
    path={["/account/:accountId", "/site/:siteId"]}
    component={BuildingAccountSwitcher}
  />
);

export default BuildingAccountSwitcherRouter;
