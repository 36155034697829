import * as React from "react";
import { Route, useParams, useRouteMatch } from "react-router-dom";
import { startCase } from "lodash";

import NavItem from "./NavItem";
import * as Icon from "../../icons";
import { useSite } from "../../context/SitesContext";

const ValinorNav = () => {
  const { siteId, page } = useParams<{
    siteId?: string;
    page?: string;
  }>();
  const currentSite = useSite(siteId);

  const baseURL = siteId != null ? `/site/${siteId}` : "";
  const isDashboard = siteId == null;
  const isSettingsPage = useRouteMatch("/settings") != null;

  const itemProps = (navPage = "", portfolioOnly = false) => ({
    urlLink: `${baseURL}/${navPage}`,
    selected: page === navPage,
    deactivated: isDashboard,
    title: startCase(navPage),
  });

  return (
    <React.Fragment>
      <NavItem
        icon={<Icon.Buildings />}
        selected={isDashboard && !isSettingsPage}
        title="Buildings"
        subtitle={currentSite && currentSite.name}
        urlLink="/"
      />

      <div className="nav-item-separator" />

      <NavItem
        icon={<Icon.BuildingDashboard />}
        {...itemProps("dashboard")}
        urlLink={`${baseURL}/dashboard/home`}
        title="Building Dashboard"
      />
      <NavItem
        icon={<Icon.BuildingInformation />}
        {...itemProps("building-information")}
      />
      <NavItem icon={<Icon.Projects />} {...itemProps("projects")} />
      <NavItem icon={<Icon.Analytics />} {...itemProps("data-visualization")} />
      <NavItem icon={<Icon.Mapping />} {...itemProps("data-mapping")} />
      <NavItem
        icon={<Icon.DataQA />}
        {...itemProps("data-qa")}
        title="Data QA"
      />
    </React.Fragment>
  );
};

const ValinorNavRouter = () => (
  <Route path={["/site/:siteId/:page?", "/"]} component={ValinorNav} />
);

export default ValinorNavRouter;
