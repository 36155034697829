import Cookies from "js-cookie";
import request from "../../../../global_functions/request";

import { bugsnagGeneralErrorHandler } from "../../../../global_functions/postgrestApi/common";
import { VITE_UPLOAD_BASE_URL } from "../../../../env";
import { DesignDocCompletenessCategory } from "../../../../types";
import { DocumentsBulkDeletion } from "./types";
import FetchClients from "../../../../global_functions/backpackSdk/client";

const designDocsBaseUrl = `${VITE_UPLOAD_BASE_URL}/design`;

export const downloadDesignDoc = (
  fileId: string | number,
  disposition: "inline" | "attachment" = "inline"
): Promise<string | null | undefined> =>
  // @ts-expect-error - TS2322 - Type 'Promise<string | void>' is not assignable to type 'Promise<string | null | undefined>'.
  request
    .get(`${designDocsBaseUrl}/download`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ file_id: fileId, disposition })
    .then((res) => res.text)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const downloadDesignDocsZip = ({
  designDocIds,
  equipmentFileIds,
  utilityBillIds,
}: {
  designDocIds: number[];
  equipmentFileIds: number[];
  utilityBillIds: number[];
}): Promise<string | undefined> => {
  return FetchClients.d3
    .GET("/files/download_zip", {
      parseAs: "text",
      params: {
        query: {
          design_doc_ids: designDocIds.join(","),
          equipment_file_ids: equipmentFileIds.join(","),
          manual_bill_file_ids: utilityBillIds.join(","),
        },
      },
    })
    .then((res) => res.data);
};
export const shareDesignDoc = (
  fileId: string | number,
  recipient: string,
  message: string
): Promise<string | null | undefined> =>
  request
    .get(`${designDocsBaseUrl}/share`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ file_id: fileId, recipient, message })
    .then((res) => res.text);

export const getPreviewDesignDoc = (
  fileId: string | number
): Promise<string | null | undefined> =>
  request
    .get(`${designDocsBaseUrl}/preview`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .query({ file_id: fileId })
    .then((res) => res.body)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const deleteDesignDoc = (
  fileId: string | number
): Promise<string | null | undefined> =>
  // @ts-expect-error - TS2322 - Type 'Promise<void | Response>' is not assignable to type 'Promise<string | null | undefined>'.
  request
    .put(`${designDocsBaseUrl}/delete/${fileId}`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .catch((error) => bugsnagGeneralErrorHandler(error));

export const categorizeDesignDoc = (
  fileIds: (string | number)[],
  category: DesignDocCompletenessCategory
) =>
  request
    .post(`${designDocsBaseUrl}/categorize`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send({
      file_ids: fileIds,
      category,
    })
    .catch((error) => bugsnagGeneralErrorHandler(error));
export const bulkDeleteDesignDocs = (
  body: DocumentsBulkDeletion
): Promise<boolean | void> =>
  request
    .post(`${designDocsBaseUrl}/delete`)
    .set("Authorization", `Bearer ${Cookies.get("jwt") || ""}`)
    .send(body)
    .then((res) => res.ok)
    .catch((e) => {
      bugsnagGeneralErrorHandler(e);
    });
