import React from "react";
import moment from "moment";
import * as Icons from "../icons";

const DayPickerNavBar = ({
  month,
  onNextClick,
  onPreviousClick,
}: {
  month: Date;
  onNextClick: () => void;
  onPreviousClick: () => void;
}) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <div className="date-selection--navbar">
      <div
        className="date-selection--navbar-button date-selection--navbar-button--prev"
        onClick={() => onPreviousClick()}
      >
        <Icons.Caret />
      </div>
      <div>{moment.tz(month, localTimezone).format("MMMM, YYYY")}</div>
      <div className="date-selection--navbar-spacer" />
      <div>
        {moment.tz(month, localTimezone).add(1, "month").format("MMMM, YYYY")}
      </div>
      <div
        className="date-selection--navbar-button date-selection--navbar-button--next"
        onClick={() => onNextClick()}
      >
        <Icons.Caret />
      </div>
    </div>
  );
};

export default DayPickerNavBar;
