import arrayMutators from "final-form-arrays";
import * as React from "react";
import {
  Form,
  FormProps,
  FormRenderProps,
  FormSpy,
  useField,
  UseFieldConfig,
  useForm,
  useFormState,
} from "react-final-form";
import classNames from "classnames";
import Input, {
  getColor,
  InputStatus,
  InputVariant,
  InputVariantUnionProps,
} from "@src/straps/base/inputs/Input/Input";
import { BackgroundColor } from "../Input/inputVariants";

import { Config, createForm, FormApi, FieldState, Mutator } from "final-form";
import { startCase } from "lodash";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import Button, { ButtonProps } from "@src/straps/base/buttons/Button/Button";
import { ClickableDiv } from "@src/straps/utils/ClickableDiv";
import Checkbox from "@src/straps/base/inputs/Checkbox/Checkbox";
import DatePickerInput, {
  DatePicker,
} from "@src/straps/base/inputs/DatePicker/DatePicker";
import Dropdown, {
  isMultipleOptionVariant,
} from "@src/straps/base/inputs/Dropdown/Dropdown";
import Icon, { IconNames } from "@src/straps/base/icons/Icon/Icon";
import Radio from "@src/straps/base/inputs/Radio/Radio";
import RadioGroup from "@src/straps/base/inputs/RadioGroup/RadioGroup";
import Schedule from "@src/straps/base/inputs/Schedule/Schedule";
import ScheduleLine from "@src/straps/base/inputs/ScheduleLine/ScheduleLine";
import { OperatingSchedule } from "@src/straps/base/inputs/ScheduleLine/types";
import Switch from "@src/straps/base/inputs/Switch/Switch";
import type { TextVariants } from "@src/straps/base/type/Text/Text";
import Textarea from "@src/straps/base/inputs/Textarea/Textarea";
import Tooltip from "@src/straps/base/dialogs/Tooltip/Tooltip";
import { ExtendedSemanticColors } from "@src/straps/colors";
import AsyncButton from "@src/straps/derived/AsyncButton/AsyncButton";
import Text from "@src/straps/base/type/Text/Text";

export const REQUIRED_ERROR_MESSAGE = "This field is required";

/**
 * Form text parameters:
 *  - Label above - margin-bottom: 12px
 *  - Support text below - margin-top: 8px
 *  - Exception is checkbox, whos label renders to the right
 */

type FormContextType = {
  variant?: InputVariant;
  inputBackgroundColor?: BackgroundColor;
  labelVariant?: TextVariants;
  labelColor?: ExtendedSemanticColors;
  labelIcon?: IconNames;
  labelIconProps?: Omit<React.ComponentProps<typeof Icon>, "name">;
  displayRequiredErrorsAfterSubmit?: boolean;
};

const FormContext = React.createContext<FormContextType>({
  variant: "rounded",
  inputBackgroundColor: undefined,
  labelVariant: undefined,
  labelColor: undefined,
  labelIcon: undefined,
  labelIconProps: undefined,
  // this prevents required errors from displaying on blur events
  displayRequiredErrorsAfterSubmit: undefined,
});

export interface StrapsFieldConfig<FieldName, FieldValue, InputValue>
  extends UseFieldConfig<FieldValue, InputValue> {
  name: FieldName;
  label?: string;
  dataTestId: string;
  required?: boolean;
  tooltip?: string | JSX.Element;
  supportText?: string;
  onBlur?: (event?: React.FocusEvent<any>) => void;
  onChange?: (event: React.ChangeEvent<any> | any) => void;
  onFocus?: (event?: React.FocusEvent<any>) => void;
  // better typing for allValues in validate callback
  validate?: (
    value: FieldValue,
    allValues: Record<string, any>,
    meta?: FieldState<FieldValue>
  ) => any;
  // By default, errors are only shown after field has been touched.
  // Setting this to true will display errors even when fields are pristine.
  alwaysDisplayErrors?: boolean;
  // display an icon to the left of the label
  labelIcon?: IconNames | true;
  labelIconProps?: Omit<React.ComponentProps<typeof Icon>, "name">;
}

type StrapsFormProps<FormValues extends Record<string, unknown>> =
  FormProps<FormValues> & {
    children?: React.ReactNode;
    render?: (renderProps: FormRenderProps<FormValues>) => React.ReactElement;
    onSubmit?: (
      values: FormValues,
      form: FormApi<FormValues, Partial<FormValues>>
    ) => unknown;
    onChange?: (arg1?: any) => void;
  };

const useFormContext = () => React.useContext(FormContext);

const setFieldTouched: Mutator<any> = (args: any[], state) => {
  const [name, touched] = args;
  const field = state.fields[name];
  if (field) {
    field.touched = !!touched;
  }
};

function StrapsForm<FormValues extends Record<string, unknown>>(
  props: StrapsFormProps<FormValues> & FormContextType
): React.ReactElement<StrapsFormProps<FormValues>> {
  const {
    children,
    onSubmit = () => {},
    render = (_: FormRenderProps<FormValues>) => {},
    onChange,
    variant = "rounded",
    labelColor,
    labelVariant: _labelVariant,
    labelIcon,
    labelIconProps,
    inputBackgroundColor,
    displayRequiredErrorsAfterSubmit,
    form,
    ...formProps
  } = props;

  const labelVariant: TextVariants =
    _labelVariant ?? (variant === "rounded" ? "sb_t-14-500" : "sa_t-16-700");

  const ctx = React.useMemo(
    () => ({
      variant,
      labelVariant,
      labelColor,
      labelIcon,
      labelIconProps,
      inputBackgroundColor,
      displayRequiredErrorsAfterSubmit,
    }),
    [
      variant,
      labelVariant,
      labelColor,
      labelIcon,
      labelIconProps,
      inputBackgroundColor,
      displayRequiredErrorsAfterSubmit,
    ]
  );

  return (
    <Form
      {...formProps}
      form={form}
      onSubmit={onSubmit}
      mutators={{ ...arrayMutators, setFieldTouched }}
      render={(formRenderProps) => (
        <FormContext.Provider value={ctx}>
          {children ?? render(formRenderProps)}
          {onChange && <OnChangeListener onChange={onChange} />}
        </FormContext.Provider>
      )}
    />
  );
}

export const useFormRef = <TFormValues extends Record<string, unknown>>(
  config?: Partial<Config<TFormValues>>
) =>
  React.useRef<FormApi<TFormValues>>(
    createForm<TFormValues>({
      onSubmit: () => {}, // onSubmit is required by the top-level form component so we can default it as noop here.
      ...config,
    })
  );

const OnChangeListener: React.FunctionComponent<{ onChange: any }> = React.memo(
  (props) => {
    const { onChange } = props;
    const { values, dirty, valid } = useFormState();
    React.useEffect(() => {
      if (dirty && valid) {
        onChange(values);
      }
    }, [dirty, values, onChange, valid]);
    return null;
  }
);

const validateRequired = (value: any) => {
  return value || value === 0 || typeof value === "boolean"
    ? undefined
    : REQUIRED_ERROR_MESSAGE;
};

export function useStrapsFormField<T, FieldName, FieldValue, InputValue>({
  name,
  label,
  required,
  tooltip,
  supportText,
  alwaysDisplayErrors,
  afterSubmit,
  allowNull,
  beforeSubmit,
  data,
  defaultValue,
  format,
  formatOnBlur,
  initialValue,
  isEqual,
  multiple,
  parse,
  subscription,
  type,
  validate,
  validateFields,
  value,
  onChange,
  onBlur,
  onFocus,
  ...componentProps
}: {
  [K in keyof T]: T[K];
} & StrapsFieldConfig<FieldName, FieldValue, InputValue>) {
  const ctx = useFormContext();
  const { submitFailed } = useFormState();
  const { input, meta } = useField(String(name), {
    afterSubmit,
    allowNull,
    beforeSubmit,
    data,
    defaultValue,
    format,
    formatOnBlur,
    initialValue,
    isEqual,
    multiple,
    parse,
    subscription,
    type,
    validateFields,
    value,
    validate: (...validateParams) => {
      if (required) {
        const result = validateRequired(validateParams[0]);
        if (result) {
          return result;
        }
      }

      return validate?.(...validateParams);
    },
  });

  const inputWithCustomHandlers: typeof input = React.useMemo(
    () => ({
      ...input,
      onChange: (e) => {
        input.onChange(e);
        onChange?.(e);
      },
      onBlur: (e) => {
        input.onBlur(e);
        onBlur?.(e);
      },
      onFocus: (e) => {
        input.onFocus(e);
        onFocus?.(e);
      },
    }),
    [input, onChange, onBlur, onFocus]
  );

  const shouldDisplayError =
    (REQUIRED_ERROR_MESSAGE === meta.error &&
      meta.touched &&
      (!ctx.displayRequiredErrorsAfterSubmit ||
        (ctx.displayRequiredErrorsAfterSubmit && submitFailed))) ||
    (meta.error !== REQUIRED_ERROR_MESSAGE && meta.touched) ||
    alwaysDisplayErrors;

  return {
    input: inputWithCustomHandlers,
    meta,
    componentProps,
    ...getInputStatus({
      supportText,
      error: shouldDisplayError && meta.error,
    }),
  };
}

type FormSubmitProps = {
  dataTestId?: string;
  onClick?: (arg1?: any) => void;
  children?: React.ReactNode;
  disableIfClean?: boolean;
  disableIfInvalid?: boolean;
};

function StrapsFormSubmit(props: FormSubmitProps & ButtonProps<"button">) {
  const { submit } = useForm();

  const {
    onClick: onClickProp,
    children,
    dataTestId,
    disableIfClean,
    disableIfInvalid,
    ...buttonProps
  } = props;

  const onClick = React.useCallback(
    async (e) => {
      onClickProp?.(e);
      await submit();
    },
    [onClickProp, submit]
  );
  const formState = useFormState();

  const disabled =
    (disableIfClean && !formState.dirty) ||
    (disableIfInvalid && !formState.valid && formState.submitFailed);

  return (
    <AsyncButton
      disabled={disabled}
      dataTestId={dataTestId}
      onClick={onClick}
      {...buttonProps}
    >
      {children}
    </AsyncButton>
  );
}

export function StrapsFormError(props: Readonly<{ dataTestId?: string }>) {
  const { variant } = useFormContext();
  return (
    <FormSpy subscription={{ errors: true }}>
      {({ errors }) =>
        (errors?._error && (
          <Text
            variant={variant === "line" ? "sb_t-14-500" : "sb_t-12-500"}
            color="negative"
            data-testid={props.dataTestId}
          >
            {errors?._error}
          </Text>
        )) ??
        null
      }
    </FormSpy>
  );
}

export type CompoundComponentProps<
  TComponent extends React.JSXElementConstructor<any>,
  FieldName extends string,
  FieldValue,
  InputValue
> = Omit<
  React.ComponentProps<TComponent>,
  keyof StrapsFieldConfig<FieldName, FieldValue, InputValue>
> &
  StrapsFieldConfig<FieldName, FieldValue, InputValue> & {
    dataTestId: string;
  };

type FormFieldNames<FormValues extends Record<string, unknown>> = Extract<
  keyof FormValues,
  string
>;
type FormFieldValue<
  FormValues extends Record<string, unknown>,
  FieldName extends keyof FormValues
> = FormValues[FieldName] | undefined;

function createStrapsFormInput<FormValues extends Record<string, unknown>>() {
  return function StrapsFormInput<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>
  >(
    props: CompoundComponentProps<
      Exclude<typeof Input, "variant">,
      FieldName,
      FieldValue,
      string
    > & {
      mRef?: React.Ref<HTMLInputElement>;
      showSupportText?: boolean;
    } & InputVariantUnionProps // needed for proper narrowing
  ) {
    const {
      variant,
      mRef,
      showSupportText = true,
      labelIcon,
      labelIconProps,
      ..._props
    } = props;
    const { required, name, label, tooltip } = _props;
    const { input, status, supportText, validation, componentProps } =
      useStrapsFormField(_props);
    const ctx = useFormContext();
    const backgroundColorProps = React.useMemo(() => {
      if (variant === "rounded") {
        return {
          backgroundColor: props.backgroundColor ?? ctx.inputBackgroundColor,
        };
      }
      if (ctx.variant === "rounded" && !variant) {
        return { backgroundColor: ctx.inputBackgroundColor };
      }
      return {};
    }, [ctx.inputBackgroundColor, ctx.variant, props, variant]);

    return (
      <label className="flex w-full flex-col items-start">
        <FormFieldContainer
          tooltip={tooltip}
          label={label}
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
          required={required}
          name={name}
          supportText={showSupportText ? supportText : undefined}
          status={status}
        >
          <Input
            {...componentProps}
            {...input}
            {...backgroundColorProps}
            required={required}
            variant={variant ?? ctx.variant}
            status={status}
            validation={validation}
            ref={mRef}
          />
        </FormFieldContainer>
      </label>
    );
  };
}

function createStrapsFormDatePicker<
  FormValues extends Record<string, unknown>
>() {
  return function StrapsFormDatePicker<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>
  >(
    props: CompoundComponentProps<
      typeof DatePicker,
      FieldName,
      FieldValue,
      string
    >
  ) {
    const { name, label, backgroundColor, handleDateChange } = props;
    const { input, status, supportText, validation, componentProps } =
      useStrapsFormField(props);

    const ctx = useFormContext();

    const { labelIcon, labelIconProps, ..._componentProps } = componentProps;

    const ref = React.useRef<HTMLLabelElement>(null);
    return (
      <label ref={ref} className="flex w-full flex-col items-start">
        <FormFieldContainer
          label={label}
          required={props.required}
          name={name}
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
        >
          <DatePickerInput
            {..._componentProps}
            status={status}
            backgroundColor={backgroundColor ?? ctx.inputBackgroundColor}
            supportText={supportText}
            validation={validation}
            name={name.toString()}
            value={input.value}
            extendContainerRefs={ref ? [ref] : []}
            handleDateChange={(e) => {
              input.onChange(e);
              handleDateChange?.(e);
            }}
          />
        </FormFieldContainer>
      </label>
    );
  };
}

function createStrapsFormTextArea<
  FormValues extends Record<string, unknown>
>() {
  return function StrapsFormTextArea<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>
  >(
    props: CompoundComponentProps<
      typeof Textarea,
      FieldName,
      FieldValue,
      string
    > & {
      mRef?: React.Ref<HTMLTextAreaElement>;
    }
  ) {
    const { name, label, disabled, variant, backgroundColor, required } = props;

    const { input, status, supportText, validation, componentProps } =
      useStrapsFormField(props);

    const ctx = useFormContext();

    const { mRef, labelIcon, labelIconProps, ...rest } = componentProps;

    return (
      <label className="flex w-full flex-col items-start">
        <FormFieldContainer
          label={label}
          required={required}
          name={name}
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
        >
          <Textarea
            {...rest}
            {...input}
            ref={mRef}
            required={required}
            disabled={disabled}
            status={status}
            variant={variant ?? ctx.variant}
            backgroundColor={backgroundColor ?? ctx.inputBackgroundColor}
            supportText={supportText}
            validation={validation}
          />
        </FormFieldContainer>
      </label>
    );
  };
}

function createStrapsFormCheckbox<
  FormValues extends Record<string, unknown>
>() {
  return function StrapsFormCheckbox<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>
  >(
    props: CompoundComponentProps<
      typeof Checkbox,
      FieldName,
      FieldValue,
      boolean
    > & { showSupportText?: boolean }
  ) {
    const { name, label } = props;
    const { input, status, supportText, componentProps } = useStrapsFormField({
      ...props,
      type: "checkbox",
    });
    const {
      showSupportText = true,
      labelIcon: _labelIcon,
      labelIconProps: _labelIconProps,
      ..._componentProps
    } = componentProps;

    const ctx = useFormContext();

    const labelIcon = _labelIcon === true ? ctx.labelIcon : _labelIcon;
    const labelIconProps = _labelIconProps ?? ctx.labelIconProps;

    return (
      <>
        <Checkbox
          {..._componentProps}
          {...input}
          labelColor={ctx.labelColor}
          labelVariant={ctx.labelVariant}
          label={
            label ?? `${startCase(name.toString())}${props.required ? "*" : ""}`
          }
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
        />
        {showSupportText && supportText && (
          <Text
            variant={ctx.variant === "line" ? "sb_t-14-500" : "sb_t-12-500"}
            color={getColor(status)}
          >
            {supportText}
          </Text>
        )}
      </>
    );
  };
}

function createStrapsFormSwitch<FormValues extends Record<string, unknown>>() {
  return function StrapsFormSwitch<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>
  >(
    props: CompoundComponentProps<typeof Switch, FieldName, FieldValue, boolean>
  ) {
    const { label, name } = props;
    const { input, status, supportText, componentProps } = useStrapsFormField({
      ...props,
      type: "checkbox",
    });

    const {
      labelIcon: _labelIcon,
      labelIconProps: _labelIconProps,
      ..._componentProps
    } = componentProps;
    const { labelVariant, labelColor, ...ctx } = useFormContext();

    const labelIcon = _labelIcon === true ? ctx.labelIcon : _labelIcon;
    const labelIconProps = _labelIconProps ?? ctx.labelIconProps;

    return (
      <label className="flex w-full flex-col items-start">
        <FormFieldContainer
          name={name}
          label=""
          required={props.required}
          status={status}
          supportText={supportText}
        >
          <Switch
            {..._componentProps}
            {...input}
            label={
              label ??
              `${startCase(name.toString())}${props.required ? "*" : ""}`
            }
            labelVariant={labelVariant}
            labelColor={labelColor}
            labelIcon={labelIcon}
            labelIconProps={labelIconProps}
          />
        </FormFieldContainer>
      </label>
    );
  };
}

function createStrapsFormRadio<FormValues extends Record<string, unknown>>() {
  return function StrapsFormRadio<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>,
    OptionValueType extends string | number | boolean | null
  >(
    props: Omit<
      CompoundComponentProps<
        typeof RadioGroup,
        FieldName,
        FieldValue,
        OptionValueType
      >,
      "children"
    > & {
      options?: { value: OptionValueType; label: string }[];
      size?: React.ComponentProps<typeof Radio>["size"];
    }
  ) {
    const { label, name, options, size } = props;
    const { input, status, supportText, componentProps } =
      useStrapsFormField(props);

    const { labelIcon, labelIconProps, ..._componentProps } = componentProps;

    const { labelVariant } = useFormContext();

    const onChangeFn: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      const { value } = e.target;
      // OptionValueType generic guarentees that value types for all options are the same
      const parseAs = typeof props.options?.[0]!.value;
      if (parseAs === "number") {
        input.onChange(Number(value));
      } else if (parseAs === "boolean") {
        input.onChange(value === "true");
      } else {
        input.onChange(value);
      }
    };

    return (
      <div>
        <FormFieldContainer
          label={label}
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
          required={props.required}
          name={name}
          status={status}
          supportText={supportText}
        >
          <RadioGroup {...input} {..._componentProps} onChange={onChangeFn}>
            {options?.map((option) => (
              <Radio
                key={option.label}
                checked={option.value === input.value}
                value={String(option.value)}
                label={option.label}
                labelVariant={labelVariant}
                size={size}
              />
            ))}
          </RadioGroup>
        </FormFieldContainer>
      </div>
    );
  };
}

/**
 * Dropdown can support single or multiple select based on variant
 * `normal` and `largeSearch` are "single" option only
 * `multiple` and `search` are "multiple" option only
 *
 * The InputValue used in the form is determined by whether the Dropdown is in single or multiple select mode
 * "single": OptionIDType
 * "multiple": OptionIDType[]
 *
 */
export function createStrapsFormDropdown<
  FormValues extends Record<string, unknown>
>() {
  return function StrapsFormDropdown<
    FieldName extends FormFieldNames<FormValues>,
    FieldValue extends FormFieldValue<FormValues, FieldName>,
    OptionIDType extends string | number = string,
    OptionMetadata extends {} | void = void
  >(
    props: Readonly<
      CompoundComponentProps<
        Exclude<
          typeof Dropdown<OptionIDType, OptionMetadata>,
          "controlledValue"
        >,
        FieldName,
        FieldValue,
        OptionIDType | OptionIDType[]
      >
    >
  ) {
    const { label, name, options, variant = "normal", onSelect, tint } = props;

    const { input, status, supportText, componentProps } =
      useStrapsFormField(props);

    const { labelIcon, labelIconProps, ..._componentProps } = componentProps;

    const ctx = useFormContext();

    const defaultValue = React.useMemo(() => {
      if (!input.value) {
        return undefined;
      }
      if (isMultipleOptionVariant(variant)) {
        const { value } = input;
        if (!Array.isArray(value)) {
          throw new Error(
            `Straps Form Dropdown FieldValue must be array using variant: ${variant}`
          );
        }
        return options.filter((option) => value.includes(option.id));
      } else {
        return options.find((option) => option.id === input.value);
      }
    }, [input, options, variant]);

    const ref = React.useRef<HTMLLabelElement>(null);

    const value = React.useMemo(
      () =>
        Array.isArray(input.value)
          ? options.filter(
              (option) =>
                Array.isArray(input.value) && input.value.includes(option.id)
            )
          : options.find((option) => option.id === input.value) ?? null,
      [input.value, options]
    );

    return (
      <label ref={ref} className="flex w-full flex-col items-start">
        <FormFieldContainer
          label={label}
          labelIcon={labelIcon}
          labelIconProps={labelIconProps}
          required={props.required}
          name={name}
          supportText={supportText}
          status={status}
        >
          <Dropdown
            {..._componentProps}
            status={status}
            defaultValue={defaultValue}
            options={options}
            extendContainerRefs={[ref]}
            variant={variant}
            tint={
              tint ??
              (ctx.inputBackgroundColor === "body" ? "darker" : "default")
            }
            controlledValue={value}
            onSelect={(option) => {
              if (!Array.isArray(option)) {
                // Single Option Select
                input.onChange(option?.id);
              } else {
                // Multiple Option Select
                input.onChange(option.map(({ id }) => id));
              }

              onSelect?.(option);
            }}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
        </FormFieldContainer>
      </label>
    );
  };
}

function StrapsFormScheduleLine<FieldName extends string>(
  props: Omit<
    CompoundComponentProps<
      typeof ScheduleLine,
      FieldName,
      OperatingSchedule,
      OperatingSchedule
    >,
    "variant" | "onChange"
  >
) {
  const { name } = props;
  const { input, status, supportText, componentProps } =
    useStrapsFormField(props);

  return (
    <FormFieldContainer
      status={status}
      supportText={supportText}
      label=""
      name={name}
    >
      <ScheduleLine
        {...componentProps}
        status={status ?? props.status}
        {...input}
      />
    </FormFieldContainer>
  );
}

function createStrapsFormSchedule<
  FormValues extends Record<string, unknown>
>() {
  return function StrapsFormSchedule<
    FieldName extends FormFieldNames<FormValues>
  >(
    props: Omit<
      CompoundComponentProps<
        typeof Schedule,
        FieldName,
        OperatingSchedule[],
        OperatingSchedule[]
      >,
      "variant" | "onChange"
    > & {
      validateLine?: (line?: OperatingSchedule) => string | undefined;
      onChange?: React.ComponentProps<typeof Schedule>["onChange"];
    }
  ) {
    const {
      label,
      name,
      required,
      validate,
      tooltip,
      validateLine,
      dataTestId,
      labelIcon,
      labelIconProps,
      onChange,
      ...rest
    } = props;

    const ctx = useFormContext();
    const { submitFailed } = useFormState();
    const { meta } = useFieldArray(name.toString(), {
      ...props,
      validate: (...validateParams) => {
        onChange?.(validateParams[0]);
        if (required) {
          const result = validateRequired(validateParams[0]);
          if (result) {
            return result;
          }
        }

        return validate?.(...validateParams);
      },
    });

    // meta.touched always false on useFieldArray so omit here
    const shouldDisplayError =
      (REQUIRED_ERROR_MESSAGE === meta.error &&
        (!ctx.displayRequiredErrorsAfterSubmit ||
          (ctx.displayRequiredErrorsAfterSubmit && submitFailed))) ||
      meta.error !== REQUIRED_ERROR_MESSAGE ||
      props.alwaysDisplayErrors;

    const arrayLevelError =
      !Array.isArray(meta.error) && shouldDisplayError && meta.error;

    return (
      <FormFieldContainer
        status={arrayLevelError && "negative"}
        supportText={arrayLevelError}
        label={label}
        labelIcon={labelIcon}
        labelIconProps={labelIconProps}
        required={required}
        name={name}
      >
        <FieldArray name={name.toString()}>
          {({ fields }) => (
            <div data-testid={dataTestId} className="flex flex-col gap-6">
              {fields?.map((line, idx) => {
                const restrictedDays = fields.value
                  ?.filter((_, index) => index !== idx)
                  .flatMap(({ days }) => [...days]);

                return (
                  <div key={line} className="flex gap-4">
                    <div className="flex flex-col gap-4">
                      <StrapsFormScheduleLine
                        name={line}
                        restrictedDays={restrictedDays}
                        isDark={
                          rest.isDark ?? ctx.inputBackgroundColor === "body"
                        }
                        dataTestId={`${props.dataTestId}${idx}`}
                        validate={validateLine}
                        status={arrayLevelError ? "negative" : undefined}
                      />
                    </div>
                    {idx !== 0 && (
                      <ClickableDiv
                        className="cursor-pointer py-1.5"
                        data-testid={`deleteScheduleLine${idx}`}
                        onClick={() => {
                          fields.remove(idx);
                        }}
                      >
                        <Icon
                          className="hover:text-straps-primary"
                          color="secondary"
                          name="delete"
                        />
                      </ClickableDiv>
                    )}
                  </div>
                );
              })}
              <Button
                className="mr-auto"
                noFrameVariant="combo"
                icon="add"
                onClick={() => {
                  fields.push({
                    startTime: "9:00",
                    endTime: "17:00",
                    days: [],
                  });
                }}
              >
                Add Schedule
              </Button>
            </div>
          )}
        </FieldArray>
      </FormFieldContainer>
    );
  };
}

export default StrapsForm;

export const FormFieldContainer = (props: {
  children: React.ReactNode;
  name: string;
  label?: string;
  labelIcon?: IconNames | true;
  labelIconProps?: Omit<React.ComponentProps<typeof Icon>, "name">;
  status?: InputStatus;
  supportText?: string;
  tooltip?: string | JSX.Element;
  required?: boolean;
}) => {
  const { label, name } = props;
  const { labelVariant, labelColor, variant, ...ctx } = useFormContext();
  const labelIcon = props.labelIcon === true ? ctx.labelIcon : props.labelIcon;
  const labelIconProps = props.labelIconProps ?? ctx.labelIconProps;
  return (
    <>
      {/* strict check for "" to remove this element altogether */}
      {label !== "" && (
        <Text color={labelColor} variant={labelVariant} className="mb-3 flex">
          {!!labelIcon && (
            <Icon
              {...labelIconProps}
              name={labelIcon}
              className={classNames("mr-1 shrink-0", labelIconProps?.className)}
            />
          )}
          {label ?? `${startCase(name.toString())}${props.required ? "*" : ""}`}
          {props.tooltip && (
            <Tooltip fixed align="topRight" content={props.tooltip}>
              <Icon name="info" className="ml-2" />
            </Tooltip>
          )}
        </Text>
      )}
      {props.children}
      {props.supportText && (
        <Text
          className="mt-2"
          variant={variant === "line" ? "sb_t-14-500" : "sb_t-12-500"}
          color={getColor(props.status)}
          data-testid={`supportText-${name.toString()}`}
        >
          {props.supportText}
        </Text>
      )}
    </>
  );
};

function getInputStatus({
  error,
  supportText,
}: {
  error?: string;
  supportText?: string;
}): {
  status?: InputStatus;
  supportText?: string;
  validation?: string;
} {
  if (error) {
    return { status: "negative", supportText: error, validation: "!" };
  }

  return { supportText };
}

export const createTypedForm = <
  FormValues extends Record<string, unknown>
>() => ({
  Form: StrapsForm<FormValues>,
  Submit: StrapsFormSubmit,
  Spy: FormSpy<FormValues>,

  // Typed Form Input Components
  TextArea: createStrapsFormTextArea<FormValues>(),
  Checkbox: createStrapsFormCheckbox<FormValues>(),
  Switch: createStrapsFormSwitch<FormValues>(),
  RadioGroup: createStrapsFormRadio<FormValues>(),
  Dropdown: createStrapsFormDropdown<FormValues>(),
  Input: createStrapsFormInput<FormValues>(),
  DatePickerInput: createStrapsFormDatePicker<FormValues>(),
  Schedule: createStrapsFormSchedule<FormValues>(),
});
