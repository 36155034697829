import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import Cookies from "js-cookie";

import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "../../postgrestApi/common";
import request from "../../request";
import type { UnitParam } from "./bill";

import { VITE_UTILITIES_BASE_URL } from "../../../env";
import { throwBackpackSuperagentResponseError } from "../errors";
import {
  useUtilitiesArcadiaQAHeader,
  UtilitiesRouteToHeader,
} from "@src/utilitiesArcadiaQA";

export type Consumption = {
  id: number;
  billRowId: number;
  meterRowId: number;
  startDate: string;
  endDate: string;
  consumption: number;
  consumptionUnit: number;
  consumptionCost: number;
  totalCost: number;
  wasteWaterConsumption?: number | null;
  wasteWaterConsumptionCost?: number | null;
  peakDemand: number | null;
  peakDemandUnit: "kW" | "kVa" | null;
  peakDemandCost: number | null;
  meterDemand: number | null;
  peakOccurance: string | null;
  powerFactor: number | null;
};

const consumption = {
  // useQuery,
  useQueryAll,
  mutations: {
    usePost,
    // usePut,
    // useDelete,
  },
} as const;

export default consumption;

// GET

export type GetConsumption = Consumption & {
  insertedBy: number;
  insertedAt: string;
  updatedBy: number;
  updatedAt: string;
};

const getAllConsumption = (
  siteId: number,
  billId: number,
  unit: UnitParam,
  routeToHeader: UtilitiesRouteToHeader
) =>
  request
    .get(
      `${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills/${billId}/meter_consumption`
    )
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .query({ unit })
    .then(({ body }) => recursiveCamelCaseCipher(body) as GetConsumption[]);

export const UTILITY_BILL_CONSUMPTION_KEY = "getUtilityBillConsumption";
function useQueryAll(
  siteId: number,
  billId: number,
  options: { unit?: UnitParam; enabled?: boolean } = {
    enabled: false,
  }
) {
  const routeToHeader = useUtilitiesArcadiaQAHeader();
  const { unit = "default", enabled } = options;

  return useReactQuery(
    [UTILITY_BILL_CONSUMPTION_KEY, siteId, billId, unit],
    () => getAllConsumption(siteId, billId, unit, routeToHeader),
    {
      enabled,
    }
  );
}

// GET /sites/{site_id}/bills/{bill_id}/meter_consumption/{id}
// ^ not needed in frontend at the moment, but currently exists in service
// also supports unit query param w/ 'raw' | 'default' as accepted values

// POST

export type PostMeterConsumptionRequest = Array<
  Partial<Omit<Consumption, "id" | "billRowId">>
>;

const postUtilityBillMeterConsumption = (
  siteId: number,
  billId: number,
  consumptionRequest: PostMeterConsumptionRequest,
  routeToHeader: UtilitiesRouteToHeader
) =>
  request
    .post(
      `${VITE_UTILITIES_BASE_URL}/sites/${siteId}/bills/${billId}/meter_consumption`
    )
    .set("Authorization", `Bearer ${Cookies.get("jwt") ?? ""}`)
    .set("X-Route-To", routeToHeader)
    .set("Content-Type", "application/json")
    .send(recursiveSnakeCaseCipher(consumptionRequest))
    .then((res) => {
      if (res.status !== 200) {
        throw new Error(
          `Unable to create meter consumption records for site: ${siteId}, bill: ${billId}`
        );
      }
    })
    .catch(throwBackpackSuperagentResponseError);

function usePost(siteId: number) {
  const queryClient = useQueryClient();
  const routeToHeader = useUtilitiesArcadiaQAHeader();

  return useMutation({
    mutationFn: ({
      billId,
      consumptionItems,
    }: {
      billId: number;
      consumptionItems: PostMeterConsumptionRequest;
    }) =>
      postUtilityBillMeterConsumption(
        siteId,
        billId,
        consumptionItems,
        routeToHeader
      ),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [UTILITY_BILL_CONSUMPTION_KEY, siteId],
      }),
    onError: (e, { billId }) => {
      throw new Error(
        `Unable to create meter consumption records for site: ${siteId}, bill: ${billId}
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
  });
}
