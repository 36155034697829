import { useState, useEffect } from "react";
import Dropdown from "../Dropdown/Dropdown";
import Button from "@src/straps/base/buttons/Button/Button";
import { xor, isArray } from "lodash";
import { OperatingSchedule, ScheduleDay, ScheduleTime } from "./types";
import moment from "moment";
import {
  timeToHourOption,
  timeToMeridiemOption,
  meridiemTimeTo24Time,
} from "./utils";
import { InputStatus } from "../Input/Input";
import Text from "../../type/Text/Text";

export const days: ScheduleDay[] = [0, 1, 2, 3, 4, 5, 6]; // Sunday is 0

type ScheduleLineProps = {
  value: OperatingSchedule;
  restrictedDays?: ScheduleDay[];
  onChange: (value: OperatingSchedule) => void;
  isDark?: boolean;
  status?: InputStatus;
  // constrainTimeInputToSingleDay?: boolean; // deprecated prop - use violatesSingleDayTimeConstraint helper function to validate externally
};

function getHoursOfDay(): string[] {
  const hours = [];
  for (let i = 1; i <= 12; i++) {
    hours.push(i + ":00");
    hours.push(i + ":30");
  }
  // 12 considered the start
  const startHours = hours.slice(-2);
  const endHours = hours.slice(0, -2);
  return [...startHours, ...endHours];
}

const allHours = getHoursOfDay().map((x) => {
  return {
    id: x,
    label: x,
  };
});

const meridiemOptions = [
  {
    id: "am",
    label: "AM",
  },
  {
    id: "pm",
    label: "PM",
  },
];

const ScheduleLine = ({
  value: schedule,
  restrictedDays,
  onChange,
  isDark,
  status,
}: ScheduleLineProps) => {
  const handleClickDays = (day: ScheduleDay) => {
    onChange({
      ...schedule,
      days: xor(schedule.days, [day]).sort(),
    });
  };

  const [startMeridiem, setStartMeridiem] = useState(
    timeToMeridiemOption(schedule.startTime)
  );

  useEffect(() => {
    if (schedule.startTime) {
      setStartMeridiem(timeToMeridiemOption(schedule.startTime));
    }
  }, [schedule.startTime]);

  const [endMeridiem, setEndMeridiem] = useState(
    timeToMeridiemOption(schedule.endTime)
  );

  useEffect(() => {
    if (schedule.endTime) {
      setEndMeridiem(timeToMeridiemOption(schedule.endTime));
    }
  }, [schedule.endTime]);

  const displayedStartTime = timeToHourOption(schedule.startTime);
  const displayedEndTime = timeToHourOption(schedule.endTime);

  const handleOnTimeChange = (
    key: "startTime" | "endTime",
    newTime: string,
    newMeridiem: string
  ) => {
    onChange({
      ...schedule,
      [key]: meridiemTimeTo24Time(`${newTime} ${newMeridiem}`) as ScheduleTime,
    });
  };

  return (
    <div className="flex flex-row flex-wrap items-center justify-start gap-2">
      <div className="w-[90px]">
        <Dropdown
          dataTestId="startHour"
          status={status}
          maxHeight={300}
          onSelect={(option) => {
            if (isArray(option)) return;
            if (option) {
              handleOnTimeChange("startTime", option.id, startMeridiem.id);
            }
          }}
          options={allHours}
          controlledValue={displayedStartTime}
          variant="normal"
          tint={isDark ? "darker" : undefined}
        />
      </div>
      <div className="w-[75px]">
        <Dropdown
          dataTestId="startMeridiem"
          status={status}
          onSelect={(option) => {
            if (isArray(option)) return;
            if (option) {
              setStartMeridiem(option);
              handleOnTimeChange("startTime", displayedStartTime.id, option.id);
            }
          }}
          options={meridiemOptions}
          controlledValue={startMeridiem}
          variant="normal"
          tint={isDark ? "darker" : undefined}
        />
      </div>
      <Text variant="sb_t-12-500">to</Text>

      <div className="w-[90px]">
        <Dropdown
          dataTestId="end-hour"
          status={status}
          maxHeight={300}
          onSelect={(option) => {
            if (isArray(option)) return;
            if (option) {
              handleOnTimeChange("endTime", option.id, endMeridiem.id);
            }
          }}
          options={allHours}
          controlledValue={displayedEndTime}
          placeholder="Select"
          variant="normal"
          tint={isDark ? "darker" : undefined}
        />
      </div>
      <div className="w-[75px]">
        <Dropdown
          status={status}
          onSelect={(option) => {
            if (isArray(option)) return;
            if (option) {
              setEndMeridiem(option);
              handleOnTimeChange("endTime", displayedEndTime.id, option.id);
            }
          }}
          options={meridiemOptions}
          placeholder="Select"
          variant="normal"
          tint={isDark ? "darker" : undefined}
          controlledValue={endMeridiem}
        />
      </div>

      <div className="flex gap-1">
        {days.map((day) => {
          return (
            <span key={day}>
              {!schedule.days.includes(day) && (
                <Button
                  outline
                  rounded
                  width="35px"
                  size="medium"
                  disabled={restrictedDays?.includes(day)}
                  onClick={() => handleClickDays(day)}
                >
                  {moment.weekdaysMin(day)}
                </Button>
              )}
              {schedule.days.includes(day) && (
                <Button
                  rounded
                  width="35px"
                  size="medium"
                  onClick={() => handleClickDays(day)}
                >
                  {moment.weekdaysMin(day)}
                </Button>
              )}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default ScheduleLine;
