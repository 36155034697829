import FetchClients, { ClientOutput } from "../client";
import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";
import { CamelCasedProperties } from "type-fest";
import {
  recursiveCamelCaseCipher,
  recursiveSnakeCaseCipher,
} from "@src/global_functions/postgrestApi";

// TODO duncan - create better query keys - I think we need better system to invalidate account query? maybe optional account aid param on all requests
const CERTIFICATIONS_QUERY_KEY = "certifications";

const certifications = {
  useQuery,
  useQueryAccount,
  mutations: {
    usePatch,
    usePost,
    useDelete,
  },
};

export type Certification = CamelCasedProperties<
  ClientOutput["site"]["Certification"]
>;
export type CertificationRequest = CamelCasedProperties<
  ClientOutput["site"]["CertificationRequest"]
>;
export type CertificationStatus = ClientOutput["site"]["CertificationStatus"];
export type CertificationType = ClientOutput["site"]["CertificationType"];

const getCertificationsForSite = (siteId: number): Promise<Certification[]> => {
  return FetchClients.site
    .GET("/{site_id}/certifications", {
      params: {
        path: {
          site_id: siteId,
        },
      },
    })
    .then((res) => res.data && recursiveCamelCaseCipher(res.data));
};

function useQuery(siteId: number) {
  return useReactQuery({
    queryKey: [CERTIFICATIONS_QUERY_KEY, siteId],
    queryFn: () => getCertificationsForSite(siteId),
  });
}

const getCertificationsForAccount = (
  account_id: number
): Promise<Certification[]> => {
  return FetchClients.certifications
    .GET("/{account_id}", {
      params: {
        path: {
          account_id,
        },
      },
    })
    .then((res) => res.data && recursiveCamelCaseCipher(res.data));
};

function useQueryAccount(accountId: number) {
  return useReactQuery({
    queryKey: [CERTIFICATIONS_QUERY_KEY],
    queryFn: () => getCertificationsForAccount(accountId),
  });
}

async function updateCertitication(
  siteId: number,
  certification: Certification
) {
  const response = await FetchClients.site.PATCH(
    "/{site_id}/certifications/{certification_id}",
    {
      params: { path: { site_id: siteId, certification_id: certification.id } },
      body: recursiveSnakeCaseCipher(certification),
    }
  );
  if (!response.data) {
    throw new Error("Failed to updated certification");
  }
  return response;
}

function usePatch(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (certification: Certification) =>
      updateCertitication(siteId, certification),
    onSettled: () =>
      queryClient.invalidateQueries([CERTIFICATIONS_QUERY_KEY, siteId]),
  });
}

async function createCertitication(
  siteId: number,
  certification: CertificationRequest
) {
  const response = await FetchClients.site.POST("/{site_id}/certifications", {
    params: { path: { site_id: siteId } },
    body: recursiveSnakeCaseCipher(certification),
  });
  if (!response.data) {
    throw new Error("Failed to create certification");
  }
}

function usePost(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (certification: Certification) =>
      createCertitication(siteId, certification),
    onSettled: () =>
      queryClient.invalidateQueries([CERTIFICATIONS_QUERY_KEY, siteId]),
  });
}

function deleteCertitication(siteId: number, certificationId: number) {
  return FetchClients.site.DELETE(
    "/{site_id}/certifications/{certification_id}",
    {
      params: { path: { site_id: siteId, certification_id: certificationId } },
    }
  );
}

function useDelete(siteId: number) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (certificationId: number) =>
      deleteCertitication(siteId, certificationId),
    onSettled: () =>
      queryClient.invalidateQueries([CERTIFICATIONS_QUERY_KEY, siteId]),
  });
}

export default certifications;
