import { Truthy } from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as R from "ramda";
type DownloadFileInput =
  | {
      url: string;
      filename: string;
    }
  | {
      blob: Blob;
      filename: string;
    };

export const downloadFile = (input: DownloadFileInput) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  // @ts-expect-error - TS2540 - Cannot assign to 'style' because it is a read-only property.
  a.style = "display: none";
  // @ts-expect-error - TS2339 - Property 'url' does not exist on type 'DownloadFileInput'. | TS2339 - Property 'blob' does not exist on type 'DownloadFileInput'.
  const url = input.url || window.URL.createObjectURL(input.blob);

  a.href = url;
  a.download = input.filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
export type DownloadFileFn = typeof downloadFile;

export const downloadFileByUrl = (url: string) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "");
  a.setAttribute("target", "_self");
  a.click();
};

export const getNameCopy = (name: string, names: Array<string>) =>
  name.replace(
    /(.*?)(?:(\s*)\(\d+\))?$/,
    (match, prefix, whitespace = " ", numberStr = "0") => {
      let number = 0;
      let copyName = name;
      while (names.includes(copyName)) {
        copyName = `${prefix}${whitespace}(${++number})`;
      }

      return copyName;
    }
  );

export const isValidPositiveFloat = (
  input: string,
  removeCommas?: boolean
): boolean =>
  /^\d*\.?\d*$/.test(removeCommas ? input.replace(/,/g, "") : input) &&
  // @ts-expect-error - TS2531 - Object is possibly 'null'.
  numeral(input).value() < Math.pow(10, 25);

export const isValidFloat = (input: string, removeCommas?: boolean): boolean =>
  /^-?\d*\.?\d*$/.test(removeCommas ? input.replace(/,/g, "") : input) &&
  // @ts-expect-error - TS2345 - Argument of type 'number | null' is not assignable to parameter of type 'number'.
  Math.abs(numeral(input).value()) < Math.pow(10, 25);

export const formatNumber = (
  value: string | number | null | undefined,
  maxDecimalPlaces: number
): string | null | undefined => {
  if (value == null) return "";
  if (typeof value === "number") value = String(value);
  if (value === ".") return value;
  if (value === "-") return value;
  const decimalIndex = value.indexOf(".");
  const truncatedValue =
    decimalIndex === -1
      ? value
      : value.slice(0, decimalIndex + maxDecimalPlaces + 1);

  const parsed = numeral(truncatedValue).value();
  if (parsed == null || Number.isNaN(parsed)) return null;

  const formatter = new Intl.NumberFormat("en-us", {
    minimumFractionDigits: Math.min(
      maxDecimalPlaces,
      (truncatedValue.split(".")[1] || "").length
    ),
    maximumFractionDigits: maxDecimalPlaces,
  });

  const trailingDecimal = value.slice(-1) === "." ? "." : "";
  return formatter.format(parsed) + trailingDecimal;
};

export const googleMapsAPIKey = "AIzaSyDbumC8gzfJgvUAZojb7llL3WgfeQkLWWE";

export const removeExtensionFromFilename = (filename: string): string =>
  R.dropLast(1, filename.split(".")).join(".");

export const getExtensionFromFilename = (filename: string): string =>
  // @ts-expect-error - TS2322 - Type 'string | undefined' is not assignable to type 'string'.
  R.last(filename.split("."));

export const fileTypes = {
  ".pdf": ".pdf",
  "application/pdf": ".pdf",
  ".docx": ".docx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
} as const;

export const arraysAreEqual = (
  left?: Array<any> | null,
  right?: Array<any> | null
) => {
  if (left === right) return true;
  if (!left || !right) return false;
  if (left.length !== right.length) return false;
  for (let i = 0; i < left.length && i < right.length; i++) {
    if (!Object.is(left[i], right[i])) return false;
  }
  return true;
};

export const rgba = (hex: string | null | undefined, opacity: number) => {
  if (!hex) return hex;
  const value = Number(`0x${hex.replace("#", "")}`);

  const r = (value >> 16) & 255;
  const g = (value >> 8) & 255;
  const b = value & 255;

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const roundToDigit = (val: number, digits: number): number =>
  Number(val.toFixed(digits));

export const getHs3JobIdFromURL = (
  linkUrl: string
): number | null | undefined => {
  const jobIdRegex =
    /^(https:\/\/)?hs3\.bractlet\.com\/jobs\/(?<jobId>\d{1,}).*/;
  const matchResult = linkUrl.match(jobIdRegex);
  if (matchResult && matchResult.groups && matchResult.groups.jobId) {
    return parseInt(matchResult.groups.jobId);
  } else {
    return null;
  }
};

export const matchEnum = <T>(val: any, enums: Array<T>): T | null | undefined =>
  enums.includes(val) ? val : null;

export const alphaNumericSortFn = <T>(
  propName: T
): ((
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
  arg1: Partial<Record<T, string>>,
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
  arg2: Partial<Record<T, string>>
) => number) => {
  const collator = new Intl.Collator("en", { numeric: true });
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'. | TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
  return (a: Partial<Record<T, string>>, b: Partial<Record<T, string>>) =>
    // @ts-expect-error - TS2345 - Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    collator.compare(a[propName], b[propName]);
};

export const alphaNumericSort = <T>(
  propName: T,
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
  arr: Array<Partial<Record<T, string>>>
  // @ts-expect-error - TS2344 - Type 'T' does not satisfy the constraint 'string | number | symbol'.
): Array<Partial<Record<T, string>>> => arr.sort(alphaNumericSortFn(propName));

// note by default this is returning the proportion of the comparison group
// that are greater than or equal to a given value
export const getPercentile = (
  value: number | null | undefined,
  comparison: Array<number>,
  highIsGood?: boolean
): number => {
  const percentile =
    value == null
      ? NaN
      : Math.round(comparison.filter((v) => value <= v).length) /
        comparison.length;
  return highIsGood && !isNaN(percentile) ? 1 - percentile : percentile;
};

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
}

export function hexToRGBValues(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1]!, 16),
        g: parseInt(result[2]!, 16),
        b: parseInt(result[3]!, 16),
      }
    : null;
}

export function hexToHSL(H: string, lightness: number) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    r = "0x" + H[1] + H[1];
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    g = "0x" + H[2] + H[2];
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    r = "0x" + H[1] + H[2];
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    g = "0x" + H[3] + H[4];
    // @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'number'.
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return "hsl(" + h + "," + s + "%," + (l + (100 - l) * (1 - lightness)) + "%)";
}

export const momentInLocalTime = (input: any) => {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return moment.tz(input, localTimezone);
};

export const asyncDebounce =
  (func: (arg1?: any) => Promise<any>, wait: number, timeoutRef: any) =>
  (...args: any): Promise<any> => {
    return new Promise(
      (
        resolve: (result: Promise<undefined | any> | undefined | any) => void
      ) => {
        if (timeoutRef.current) timeoutRef.current();
        const timerId = setTimeout(() => {
          resolve(func(...args));
        }, wait);
        timeoutRef.current = () => {
          clearTimeout(timerId);
          // @ts-expect-error - TS2794 - Expected 1 arguments, but got 0. Did you forget to include 'void' in your type argument to 'Promise'?
          resolve();
        };
      }
    );
  };

export const getTitleSegments = (name: string | undefined, section: string) => {
  return name ? `${name} | ${section}` : `${section}`;
};

// Joins paths with leading or trailing slashes while avoiding `//`
// Workaround react router v5 issue: https://github.com/remix-run/react-router/issues/4841
export function joinPaths(...segments: string[]) {
  return segments.join("/").replace(/\/+/g, "/");
}

// typesafe way to filter falsy values out of an array
export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}

// This function must be called from a user activation event (ie an onclick event)
export const openFileBrowser = (
  acceptedFileTypes: string,
  onSelect: (files: File[]) => void,
  multiple = false
) => {
  const inputElement = document.createElement("input");
  // Hide element and append to body (required to run on iOS safari)
  inputElement.style.display = "none";
  document.body.appendChild(inputElement);
  inputElement.type = "file";
  inputElement.dataset.testid = "file-browser-input";

  if (acceptedFileTypes !== "*") inputElement.accept = acceptedFileTypes;
  inputElement.multiple = multiple;
  inputElement.addEventListener("change", (e) => {
    const inputEl = e.target as HTMLInputElement;
    const files = inputEl.files ? Array.from(inputEl.files) : [];
    onSelect(files);
    document.body.removeChild(inputElement);
  });

  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent("click"));
};

export function raise(message: string): never {
  throw new Error(message);
}

export function getObjectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as Array<Extract<keyof T, string>>;
}

export function getObjectEntries<T extends object>(obj: T) {
  return Object.entries(obj) as Array<
    [Extract<keyof T, string>, T[Extract<keyof T, string>]]
  >;
}

// Basically R.groupBy but with good Typing...
export function groupBy<T, K extends keyof T>(
  array: T[],
  key: K
): Record<string, T[]> {
  return array.reduce((result, currentItem) => {
    const keyValue = currentItem[key];

    const keyValueStr = String(keyValue);

    if (!result[keyValueStr]) {
      result[keyValueStr] = [];
    }

    result[keyValueStr]!.push(currentItem);

    return result;
  }, {} as Record<string, T[]>);
}

export function median(arr: number[]): number | undefined {
  if (!arr.length) return undefined;
  const s = [...arr].sort((a, b) => a - b);
  const mid = Math.floor(s.length / 2);
  return s.length % 2 ? s[mid]! : (s[mid - 1]! + s[mid]!) / 2;
}
