import React from "react";
import classNames from "classnames";
import { IconNames } from "./types";
// import { useQuery } from "react-query";
import styles from "./Icon.module.scss";
import { useAsyncLazy } from "../../../../global_functions/useAsync";
import { ExtendedSemanticColors } from "../../../colors";

type IconsImport = Record<
  string,
  () => Promise<React.FunctionComponent<React.SVGProps<SVGSVGElement>>>
>;
const actionIcons = import.meta.glob("./svgs/actions/*.svg", {
  query: "?react",
  import: "default",
}) as IconsImport;
const directionIcons = import.meta.glob("./svgs/direction/*.svg", {
  query: "?react",
  import: "default",
}) as IconsImport;
const labelIcons = import.meta.glob("./svgs/labels/*.svg", {
  query: "?react",
  import: "default",
}) as IconsImport;
const integrationIcons = import.meta.glob(
  "./svgs/external-integrations/*.svg",
  {
    query: "?react",
    import: "default",
  }
) as IconsImport;
const brandIcons = import.meta.glob("./svgs/brand/*.svg", {
  query: "?react",
  import: "default",
}) as IconsImport;

enum Category {
  Action = "Action",
  Direction = "Direction",
  Label = "Label",
  Integration = "Integration",
}

const colorClasses: Record<ExtendedSemanticColors, string> = {
  white: "text-white",
  primary: "text-straps-primary",
  "primary-hover": "text-straps-primary-hover",
  secondary: "text-straps-secondary",
  "secondary-hover": "text-straps-secondary-hover",
  tertiary: "text-straps-tertiary",
  hyperlink: "text-straps-hyperlink",
  "hyperlink-hover": "text-straps-hyperlink-hover",
  body: "text-straps-body",
  brand: "text-straps-brand",
  "brand-hover": "text-straps-brand-hover",
  warning: "text-straps-warning",
  "warning-hover": "text-straps-warning-hover",
  "warning-bg": "text-straps-warning-bg",
  positive: "text-straps-positive",
  "positive-hover": "text-straps-positive-hover",
  "positive-bg": "text-straps-positive-bg",
  negative: "text-straps-negative",
  "negative-hover": "text-straps-negative-hover",
  "negative-bg": "text-straps-negative-bg",
  "accent-1": "text-straps-accent-1",
  "accent-1-hover": "text-straps-accent-1-hover",
  "accent-2": "text-straps-accent-2",
  "accent-3": "text-straps-accent-3",
};

const sizeMap = {
  small: "s",
  medium: "m",
  large: "l",
  extraLarge: "xl",
};

const Icon = React.forwardRef<
  HTMLSpanElement,
  {
    name: IconNames;
    size?: "extraLarge" | "large" | "medium" | "small";
    color?: ExtendedSemanticColors;
    iconTextClass?: string; // avoid using this prop
    className?: string;
  } & React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
>(
  (
    { name, size = "medium", className, color, iconTextClass, ...spanProps },
    ref
  ) => {
    const [{ data, success, error }] = useAsyncLazy(async () => {
      let importPath =
        integrationIcons[`./svgs/external-integrations/${name}.svg`] ||
        brandIcons[`./svgs/brand/${name}.svg`];

      if (!!importPath) {
        const component = await importPath();
        return {
          component,
          category: Category.Integration,
        };
      }

      importPath =
        directionIcons[`./svgs/direction/${name}=${sizeMap[size]}.svg`] ||
        actionIcons[`./svgs/actions/${name}=${sizeMap[size]}.svg`] ||
        labelIcons[`./svgs/labels/${name}=${sizeMap[size]}.svg`];

      if (!importPath) {
        throw new Error(`Icon not found for: ${name}`);
      }

      const component = await importPath();
      return {
        component,
        category: Category.Action,
      };
    }, [name, size]);

    const sizeClasses = React.useMemo(() => {
      if (data?.category === Category.Integration) {
        return classNames({
          "h-[16px] w-[16px]": size === "small",
          "h-[20px] w-[20px]": size === "medium",
          "h-[30px] w-[30px]": size === "large",
          "h-[40px] w-[40px]": size === "extraLarge",
        });
      }
      return classNames({
        "h-[12px] w-[12px]": size === "small",
        "h-[16px] w-[16px]": size === "medium",
        "h-[20px] w-[20px]": size === "large",
        "h-[24px] w-[24px]": size === "extraLarge",
      });
    }, [data?.category, size]);

    return (
      <span
        aria-label={name}
        role="img"
        {...spanProps}
        className={classNames(
          className,
          "inline-flex",
          sizeClasses,
          color && colorClasses[color], // if no color is passed, this will default to parents 'text color'
          iconTextClass
        )}
        ref={ref}
      >
        {/* Show blank square if unable to fetch icon */}
        {error && <div className="h-full w-full border-2 border-current" />}
        {/* If success render icon */}
        {success &&
          data?.component &&
          React.createElement(data.component, {
            className:
              data.category === Category.Integration
                ? styles.integration_icon
                : styles.icon,
            width: "100%",
            height: "100%",
          })}
      </span>
    );
  }
);

export default Icon;

export * from "./types";
